<template>
  <v-btn v-if="userDetails.role.code === 2" @click="logClockin" :loading="disableClockIn">
    {{ webClockinObj.showRunningTime ? webClockinObj.elapsedTime : 'Web Clockin' }}
  </v-btn>
</template>

<script>
import moment from 'moment'
export default {
  data () {
    return {
      disableClockIn: false,
      webClockinObj: {}
    }
  },
  mounted () {
    if (this.userDetails.role.code === 2) this.updateWebClockinWidget() // 2 == staff
  },
  methods: {
    updateWebClockinWidget () {
      this.webClockinObj = { showRunningTime: false } // 1 -- not running, 2 --> running
      this.$_execute("get", "staffAttendance/get_clockin_info").then(({ data }) => {
        if (data && data.length && data[0].attendance && data[0].attendance.length) {
          let currentDateAttendance = data[0]
          let staffAttendanceIndex = currentDateAttendance.attendance.findIndex(attendance => attendance.user === this.userDetails._id)
          if (staffAttendanceIndex >= 0) {
            let staffAttendanceObj = currentDateAttendance.attendance[staffAttendanceIndex]
            var currentlyRunningLogIndex = staffAttendanceObj.logs.findIndex(x => x.clockoutTime == null)
            if (currentlyRunningLogIndex >= 0) {
              this.webClockinObj.showRunningTime = true
              this.webClockinObj.clockinTime = staffAttendanceObj.logs[currentlyRunningLogIndex].clockinTime
              this.webClockinObj.clockinTimeObj = moment(staffAttendanceObj.logs[currentlyRunningLogIndex].clockinTime, 'HH:mm:ss')
              setInterval(() => {
                var elapsedTime = this.calculateElapsedMillisecondsToHour(this.webClockinObj.clockinTimeObj)
                this.$set(this.webClockinObj, 'elapsedTime', elapsedTime)
              }, 1000);
            }
          }
        }
      });
    },
    calculateElapsedMillisecondsToHour(clockinTime) {
      var elapsedTime = moment() - clockinTime 
      elapsedTime = elapsedTime / 1000 // Convert time difference from milliseconds to seconds
      let seconds = Math.floor(elapsedTime % 60) // Pad seconds with a zero (if necessary) and convert to string
      let secondsAsString = seconds < 10 ? "0" + seconds : seconds;
      elapsedTime = Math.floor(elapsedTime / 60) // Convert time difference from seconds to minutes using %
      let minutes = elapsedTime % 60      // Extract integer minutes that don't form an hour using %
      let minutesAsString = minutes < 10 ? "0" + minutes : minutes      // Pad minutes with a zero (if necessary) and convert to string
      elapsedTime = Math.floor(elapsedTime / 60)      // Convert time difference from minutes to hours
      let hours = elapsedTime % 24      // Extract integer hours that don't form a day using %
      elapsedTime = Math.floor(elapsedTime / 24)      // Convert time difference from hours to days
      let days = elapsedTime      // The rest of elapsedTime is number of days
      let totalHours = hours + (days * 24); // add days to hours
      let totalHoursAsString = totalHours < 10 ? "0" + totalHours : totalHours;
      // console.log(`${totalHoursAsString}:${minutesAsString}:${secondsAsString}`);
      return `${totalHoursAsString}:${minutesAsString}:${secondsAsString}`
    },
    logClockin () {
      this.$_execute("get", "staffAttendance/log_hour").then(() => {
        this.updateWebClockinWidget()
      }).finally(() => {
        this.disableClockIn = true
        setTimeout(() => { this.disableClockIn = false }, 5000) // 10 seconds
      })
    }
  }
}
</script>

<style>

</style>
